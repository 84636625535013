import { AppProps } from "next/app";
import * as React from "react";
import Script from "next/script";
import {
  lightTheme,
  darkTheme,
} from "@gcp-artifact/compass/lib/foundation/theme";
import { ThemeProvider } from "styled-components";
import { CookiesProvider, useCookies } from "react-cookie";
import { polyfill } from "interweave-ssr";

import "../styles/globals.css";
import GlobalStyles from "@gcp-artifact/compass/lib/foundation/GlobalStyles";
import ErrorBoundary from "@/components/ErrorBoundary";
import Layout from "@/components/Layout";
import MainStoreProvider from "@/services/store/adminState/adminStateContext";
import Head from "next/head";
import useAdminStore from "@/services/store/useAdminStore";
import { initCookiebotScript } from "@/services/cookiebot/initCookiebot";
import config from "@/config";
import { OpenReplay } from "@/services/openreplay/openreplay";
import LoadingAnimation from "@/components/LoadingAnimation";
import MPStorage from "@/services/LocalStorage";
import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import {
  MPInstanceAds,
  MPInstanceAdsV2,
} from "@/services/api/RequestAxios/RequestAxiosAds";
import { MPInstanceTos } from "@/services/api/RequestAxios/RequestAxiosToS";
import { MPInstanceAuthX360 } from "@/services/api/RequestAxios/RequestAxiosAuthX360";

polyfill();

function MyApp({ Component, pageProps }: AppProps) {
  const [theme] = React.useState("light");
  const [cookie] = useCookies(["auth_c"]);

  // Authenticate with cookie from Campaigns
  React.useEffect(() => {
    if (!cookie?.auth_c || cookie?.auth_c === "null") return;

    const { token, accessToken, expiresIn } = cookie.auth_c || {};

    const authBearerToken = accessToken || token;

    MPStorage.saveCacheAuthenticate({
      accessToken: authBearerToken,
      expiresIn,
    });

    MPInstancesHub.setAuthorizationToken(authBearerToken);
    MPInstanceAds.setAuthorizationToken(authBearerToken);
    MPInstanceAdsV2.setAuthorizationToken(authBearerToken);
    MPInstanceTos.setAuthorizationToken(authBearerToken);
    MPInstanceAuthX360.setAuthorizationToken(authBearerToken);
  }, [cookie?.auth_c]);

  React.useEffect(() => {
    if (window) {
      (window as any).fbAsyncInit = function () {
        (window as any)?.FB?.init?.({
          appId: config.facebookAppId,
          cookie: true,
          xfbml: true,
          version: "v20.0",
        });

        console.log("Facebook SDK initialized");
      };
    }
  }, []);

  const { adminNotification } = useAdminStore();
  const [, setCookie] = useCookies([
    "cookieConsent",
    "cookieAnalytics",
    "cookieMarketing",
    "cookiePreferences",
  ]);

  React.useEffect(() => {
    initCookiebotScript(setCookie, adminNotification);
  }, []);

  React.useEffect(() => {
    (async function () {
      const tracker = await OpenReplay.initOpenReplay(
        config.OPEN_RELAY_PROJECT_KEY
      );
      tracker?.start();
    })();
  }, []);

  return (
    <ThemeProvider theme={theme == "light" ? lightTheme : darkTheme}>
      <CookiesProvider>
        <GlobalStyles />
        <React.Suspense fallback={<LoadingAnimation />}>
          <ErrorBoundary>
            <MainStoreProvider>
              <Layout>
                <Head>
                  <meta
                    name="robots"
                    content="nofollow, noindex, noimageindex, noarchive, nosnippet"
                  />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                  />
                  <meta
                    name="description"
                    content={config.SEOMetaDescription}
                  />
                </Head>
                <Script
                  id="google-tag-manager"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W75TC92');
                    `,
                  }}
                />
                <Script
                  async
                  defer
                  src="https://connect.facebook.net/en_US/sdk.js"
                />
                <Component {...pageProps} />
              </Layout>
            </MainStoreProvider>
          </ErrorBoundary>
        </React.Suspense>
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default MyApp;
